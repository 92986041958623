import { STATE_IDLE } from "@/config/contants";

type TypeModuleState = {
  state: TypeState;
};

const initialState: TypeModuleState = {
  state: STATE_IDLE,
};

const moduleApp = {
  state: (): TypeModuleState => initialState,
  mutations: {},
  actions: {},
  getters: {},
};

export default moduleApp;
