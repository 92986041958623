
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    msg: String,
  },
  components: {
    BottomBar,
  },
})
export default class BottomBar extends Vue {
  tabList = [
    {
      text: "策略",
      routeName: "Home",
      iconName: "home",
      params: {},
    },
    {
      text: "实盘",
      routeName: "stragety",
      iconName: "stragety",
      params: {},
    },
    {
      text: "社区",
      routeName: "community",
      iconName: "community",
      params: {},
    },
  ];
}
