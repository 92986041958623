import { createStore } from "vuex";
import app from "@/store/modules/app";
import authorization from "@/store/modules/authorization";

export default createStore({
  state: {
    app,
    authorization,
  },
  mutations: {},
  actions: {},
  modules: {},
});
